import React from 'react';
import PlacementCard from './PlacementCard';

const PlacementHighlightBanner = () => {
  return (
    <section className="mx-auto max-w-6xl px-4 py-12 text-center">
      {/* Top highlight section */}
      <PlacementCard />

      {/* Bottom CTA */}
      <h2 className="mb-4 text-3xl font-bold">
        1000+ Learners Placed In Top Companies
      </h2>
      <button className="rounded-md bg-yellow-400 px-6 py-3 font-semibold text-black shadow-md hover:bg-yellow-500">
        Book Your Free Trial, Now →
      </button>
    </section>
  );
};

export default PlacementHighlightBanner;
