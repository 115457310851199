import React from 'react';

const testimonials = [
  {
    name: 'Siddhendra Sai Sanjamala',
    time: '',
    stars: 5,
    text: `I joined Crio.do 3 months ago, and the experience has been nothing short of amazing! The project-based learning approach is truly effective, allowing me to work on real-world problems right from the start. The mentors are incredibly knowledgeable and supportive, always ready to guide and clarify doubts.

The structured curriculum ensures a smooth learning curve, and the hands-on projects help in building confidence and practical skills. The community is vibrant and collaborative, making the entire learning journey enjoyable and productive.`,
  },
  {
    name: 'Dnyaneshwar Patil',
    time: '',
    stars: 5,
    tag: '',
    text: `I had an amazing experience with this fellowship program! The mentor was incredibly knowledgeable and supportive, always ready to guide us through challenges. The curriculum was well-structured, covering key concepts in a practical and engaging manner. What impressed me the most was the technical support—fast and efficient, ensuring that no one was stuck for long. Overall, it’s a fantastic program for anyone looking to grow their skills in a supportive learning environment!`,
  },
  {
    name: 'Pujashankar',
    time: '',
    stars: 5,
    tag: '',
    text: `If you’re serious about leveling up your software development skills and prefer a hands-on approach over theoretical learning, Crio.Do is a great investment. It’s particularly useful for freshers and early-career professionals looking to gain practical experience and stand out in job applications.`,
  },
  {
    name: 'Teja Talari',
    time: '',
    stars: 5,
    text: `I joined this community and looks everything very good. This is a good platform for learning deeply with hands-on experience. I can say this course covers a wide range of topics. I can say whoever is struggling without knowledge, through this platform you can gain knowledge easily and secure your job in the software industry. The course structure is well organized, making the learning journey smooth and enjoyable.

The mentors are highly knowledgeable, I’ve gained more practical knowledge than I ever thought possible. Crio.do is the perfect platform for anyone looking to excel in software development. Highly recommended..!`,
  },
  {
    name: 'Vishnuvardhan Kanna',
    time: '',
    stars: 5,
    tag: '',
    text: `Crio.do is an excellent platform for learning Data Science, Data Analytics, and Software Development. The hands-on, project-based approach makes it stand out from traditional courses. The structured curriculum, real-world projects, and mentorship support provide a great learning experience!`,
  },
  {
    name: 'Kakumanu Venu Alekhya',
    time: '',
    stars: 5,
    text: `It is a great learning platform. I joined Crio.do a month ago, and the first sprint was incredible! The mentors were highly supportive, the curriculum was well-organized, and I gained valuable knowledge through hands-on projects.`,
  },
];

const StarRating = ({ count = 5 }) => (
  <div className="text-sm text-yellow-500">
    {'★'.repeat(count)}
    {'☆'.repeat(5 - count)}
  </div>
);

const TestimonialSection = () => {
  return (
    <div className="px-4 py-10 lg:px-12">
      <h2 className="mb-8 text-center text-3xl font-bold text-gray-800">
        What Our Learners Say
      </h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
        {testimonials.map((t, i) => (
          <div
            key={i}
            className="rounded-lg border bg-white p-5 shadow-sm transition-shadow duration-200 hover:shadow-md"
          >
            <div className="mb-2 flex items-center justify-between">
              <h4 className="text-base font-semibold text-gray-800">
                {t.name}
              </h4>
              <span className="text-xs text-gray-500">{t.time}</span>
            </div>
            <div className="mb-3 flex items-center gap-2">
              <StarRating count={t.stars} />
              {t.tag && (
                <span className="rounded bg-gray-200 px-2 py-0.5 text-xs font-medium text-gray-700">
                  {t.tag}
                </span>
              )}
            </div>
            <p className="whitespace-pre-wrap text-sm text-gray-700">
              {t.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSection;
