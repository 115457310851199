import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const PlacementCard = () => {
  return (
    <div className="flex max-h-[460px] flex-col items-center justify-center rounded-[12px] bg-[#B9CFCA] bg-opacity-30 pt-10 md:w-[1032px] md:flex-row md:pt-0">
      {/* Top text and logo */}
      <div>
        <p className="text-lg text-gray-800 sm:text-xl">
          Neha got{' '}
          <span className="font-semibold text-green-700">placed in</span>
        </p>
        <div className="mt-2 flex items-center justify-center gap-2 text-3xl font-bold text-gray-800 sm:justify-start">
          <StaticImage
            src="../../images/PlacementsPage/Cimpress.png"
            alt="Learner 2"
            className="h-[50px] w-[290px]"
          />
        </div>
      </div>

      {/* Person Image */}
      <div className="flex justify-center">
        <div className="relative">
          <StaticImage
            src="../../images/PlacementsPage/TestimonialNeha.png"
            alt="Learner 2"
            className="relative z-10 max-h-[350px] w-[350px] rounded-md object-contain"
          />
        </div>
      </div>
    </div>
  );
};

export default PlacementCard;
