import React from 'react';

const StoryModal = ({ story, onClose }) => {
  if (!story) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/60 px-4">
      <div className="relative w-full max-w-[640px] rounded-xl bg-white p-5 shadow-xl">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-xl font-bold text-gray-500"
        >
          ×
        </button>

        <div className="mb-4 flex items-center justify-between">
          <div className="flex items-center gap-3">
            {story.imageUrl && (
              <img
                src={`/images/strapi/success-stories/${story.imageUrl}`}
                alt={story.name}
                className="h-[64px] w-[64px] rounded-md object-cover"
              />
            )}
            <div>
              <p className="font-semibold">{story.name}</p>
              <p className="text-sm text-gray-500">
                {story.position} {story.company && <>at {story.company}</>}
              </p>
            </div>
          </div>
          <div>
            {story.companyLogoUrl && (
              <img
                src={`/images/strapi/success-stories/${story.companyLogoUrl}`}
                alt={story.name}
                className="h-[40px] w-[112px] rounded-md object-contain"
              />
            )}
          </div>
        </div>

        <div className="mb-4">
          <iframe
            src={story.videoUrl}
            title="Success Story"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="h-[300px] w-full rounded-md"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default StoryModal;
