import React, { useEffect, useRef } from 'react';

const ImageMarquee = () => {
  const containerRef = useRef(null);
  const scrollSpeed = 1; // pixels per frame

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let animationFrame;

    const scroll = () => {
      container.scrollLeft += scrollSpeed;
      if (container.scrollLeft >= container.scrollWidth / 2) {
        container.scrollLeft = 0;
      }
      animationFrame = requestAnimationFrame(scroll);
    };

    animationFrame = requestAnimationFrame(scroll);
    return () => cancelAnimationFrame(animationFrame);
  }, []);

  return (
    <div className="w-full overflow-hidden bg-white">
      <div
        ref={containerRef}
        className="flex w-full overflow-x-scroll scrollbar-hide"
        style={{ scrollBehavior: 'auto', whiteSpace: 'nowrap' }}
      >
        {/* Repeat image for seamless scroll */}
        <div className="flex w-max">
          {[1, 2, 3, 4].map((el) => (
            <>
              <img
                src="/images/HeroImage.webp"
                alt="Marquee Image"
                className="h-[350px] w-auto object-cover md:h-[500px]"
              />
              <img
                src="/images/HeroImage.webp"
                alt="Duplicate"
                className="ml-4 h-[350px] w-auto object-cover md:h-[500px]"
              />
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageMarquee;
